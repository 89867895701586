import $ from "jquery";
import * as bootstrap from 'bootstrap'

/* bootstrap toast on save-btn */
$(function() {

    const toastTrigger = document.getElementById('saveToastBtn')
    const toastLiveExample = document.getElementById('saveToast')
    if (toastTrigger) {
    toastTrigger.addEventListener('click', () => {
        const toast = new bootstrap.Toast(toastLiveExample)

        toast.show()
    })
    }

});


/* switch to fixed form-footer */
/* https://codepen.io/gnowland/pen/XMxMeM */
function constrainFixedScroll(el) {
    var $el = $(el);
    var windowHeight = $(window).height();
    var percentBottom = $el.data("offset-bottom-percent");
    var fixedPosition = windowHeight * (percentBottom / 100);
    var footerVisible = 0;
    if($('.end-of-form').lenght > 0 ){
      footerVisible = (windowHeight - $('.end-of-form').offset().top ) + $(window).scrollTop();
    }
    if($el.hasClass('fixed')) fixedPosition = fixedPosition + $el.outerHeight();
    
    var delta = footerVisible - fixedPosition;
    
    if( delta > 0 ) {
      $el.removeClass('fixed');
    } else {
      $el.addClass('fixed');
    }
    
    //Debug
    /* $('footer').html('Footer<br>Position: ' + fixedPosition + 'px<br>Visible: ' + footerVisible + 'px<br>Delta: ' + delta + 'px'); */
  }
  
  $(window).on("orientationchange load resize scroll", function () {
    constrainFixedScroll('#block');
  });